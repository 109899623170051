'use strict'

import 'flatpickr/dist/flatpickr.css'
import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de'
import { Dutch } from 'flatpickr/dist/l10n/nl'
import { english } from 'flatpickr/dist/l10n/default'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { Italian } from 'flatpickr/dist/l10n/it'
import { Spanish } from 'flatpickr/dist/l10n/es'
import { Options } from 'flatpickr/dist/types/options'
import { CustomLocale, Locale } from 'flatpickr/dist/types/locale'
import { Instance } from 'flatpickr/dist/types/instance'

export type CalendarOptions = Options

export type Calendar = Instance | undefined

interface Calendars {
  [key: string]: Calendar
}

const languagesMapping: Record<string, CustomLocale | Locale> = {
  de: German,
  en: english,
  es: Spanish,
  fr: French,
  it: Italian,
  nl: Dutch,
}

const calendars: Calendars = {}

window.addEventListener('touchstart', function (e) {
  const target = e.target as HTMLElement

  if (document.querySelector('.flatpickr-calendar.open') && !target.closest('.flatpickr-calendar') && target !== document.body) {
    document.body.click()
  }
})

export default {
  initCalendar(id: string, el: Element, config: CalendarOptions, language: string): Calendar {
    if (!calendars[id] && languagesMapping[language]) {
      config.locale = languagesMapping[language]

      calendars[id] = flatpickr(el, config)

      el.addEventListener('clear', () => {
        calendars[id]?.clear()
      })
    }

    return calendars[id]
  },

  getCalendar(id: string): Calendar {
    return calendars[id]
  },

  getValue(id: string): string {
    return calendars[id]?.input.value ?? ''
  },

  getCalendarElement(id: string): HTMLInputElement | undefined {
    return calendars[id]?.input
  },

  setDate(id: string, date: string): boolean {
    if (!id || !date) {
      return false
    }

    calendars[id]?.setDate(date, true, 'Y-m-d')

    return true
  },

  removeCalendar(id: string): boolean {
    if (calendars[id]) {
      calendars[id]?.destroy()
      delete calendars[id]
    }

    return true
  },
}
