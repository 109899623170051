'use strict'

export default function (): void {
  const focusBlockFn = function (this: HTMLElement): void {
    this.closest('.search-form__block')?.classList.add('dca-form__field--focused')
  }

  const unfocusBlockFn = function (this: HTMLElement): void {
    this.closest('.search-form__block')?.classList.remove('dca-form__field--focused')
  }

  const changeFn = function (this: HTMLInputElement | HTMLSelectElement): void {
    if (this.value === '') {
      this.closest('.search-form__block')?.classList.remove('dca-form__field--filled')
    } else {
      this.closest('.search-form__block')?.classList.add('dca-form__field--filled')
    }
  }

  const clearFieldFn = function (this: HTMLInputElement | HTMLSelectElement, e: Event): void {
    e.preventDefault()
    e.stopPropagation()

    const inputEl = this.parentNode?.querySelector('.search-form__field') as HTMLInputElement | HTMLSelectElement
    inputEl.value = ''

    const event = new CustomEvent('clear')
    inputEl.dispatchEvent(event)
  }

  document.querySelectorAll('.search-form__field').forEach(function (el) {
    el.addEventListener('showDropdown', focusBlockFn)

    el.addEventListener('hideDropdown', function (this: HTMLInputElement | HTMLSelectElement) {
      this.closest('.search-form__block')?.classList.remove('dca-form__field--focused')

      changeFn.bind(this)()
    })

    el.addEventListener('clear', changeFn)
  })

  document.querySelectorAll('.search-form__list-toggle').forEach(function (el) {
    el.addEventListener('showMenu', focusBlockFn)
    el.addEventListener('hideMenu', unfocusBlockFn)
  })

  document.querySelectorAll('.search-form__list-field').forEach(function (el) {
    el.addEventListener('click', function (this: HTMLElement) {
      if (this.closest('.dca-list')?.querySelectorAll('.search-form__list-field:checked').length === 0) {
        this.closest('.search-form__block')?.classList.remove('dca-form__field--filled')
      } else {
        this.closest('.search-form__block')?.classList.add('dca-form__field--filled')
      }
    })
  })

  document.querySelectorAll('.search-form .field__reset').forEach(function (el) {
    el.addEventListener('click', clearFieldFn)
    el.addEventListener('touchstart', clearFieldFn)
  })

  if (document.querySelector('.search-form__wrapper form.search-form')) {
    document.querySelector('.search-form__wrapper form.search-form')?.addEventListener('submit', function (this: HTMLElement) {
      const submitEl = this.querySelector('.search-form__submit')

      if (!submitEl?.classList.contains('submit--loading')) {
        setTimeout(() => {
          submitEl?.classList.add('submit--loading')
        }, 0)

        const submitInputEl = document.getElementById('search_offers_search')

        if (submitInputEl && submitInputEl.hasAttribute('data-loading-message')) {
          submitInputEl.innerText = submitInputEl.getAttribute('data-loading-message') as string
        }
      }
    })
  }
  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      // Hide the loader when the page is fetched from bfcache.
      const submitEl = document.body.querySelector('.search-form__submit.submit--loading')
      submitEl?.classList.remove('submit--loading')
    }
  })
}
