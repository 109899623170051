'use strict'

import SearchAutocomplete from 'assets/core/js/module/searchAutocomplete'

export default function (): SearchAutocomplete | boolean {
  if (document.getElementById('autocomplete-container')) {
    return new SearchAutocomplete(document.getElementById('autocomplete-container') as HTMLElement)
  }

  return false
}
