'use strict'

import SearchCalendar from 'assets/core/js/module/searchCalendar'

export default function (): SearchCalendar {
  return new SearchCalendar(document.getElementById('search_offers_checkInDate') as HTMLElement, {
    onChange: function (element) {
      element.closest('.search-form__block')?.classList.add('dca-form__field--filled')
    },
    onOpen: function (element) {
      element.closest('.search-form__block')?.classList.add('dca-form__field--focused')
    },
    onClose: function (element) {
      element.closest('.search-form__block')?.classList.remove('dca-form__field--focused')
    },
    numberOfMonths: 1,
  })
}
