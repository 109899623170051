'use strict'

import * as coreCommon from 'assets/core/js/common'
import { Gesture } from '@use-gesture/vanilla'
import { BaseOptions } from 'flatpickr/dist/types/options'
import mobileBottomPanel from 'assets/core/js/module/mobileBottomPanel'
import CalendarManager, { Calendar } from './calendarManager'

interface SearchCalendarConfig {
  onChange?(element: Element): void
  onOpen?(element: Element): void
  onClose?(element: Element): void
  onInitWrapper?(calendar: Calendar, wrapperEl: HTMLElement): void
  onDayOver?(calendarContainer: HTMLElement, el: HTMLElement, daysAfter: HTMLElement[]): void
  onDayOut?(calendarContainer: HTMLElement, el: HTMLElement): void
  numberOfMonths?: number
  withNights?: boolean
}

export default class SearchCalendar {
  element!: Element
  altElement!: HTMLInputElement
  parentEl!: Element
  config!: SearchCalendarConfig
  mobileBottomPanelCloseEl!: HTMLElement | null | undefined

  constructor(element: string | HTMLElement, userConfig: SearchCalendarConfig = {}) {
    let el: Element | string | null = element

    if (typeof element === 'string') {
      el = document.querySelector(element)
    }

    if (!el || typeof el === 'string') {
      return
    }

    this.element = el

    this.config = userConfig

    const currentLocale = document.body.getAttribute('data-locale')?.split('_')
    const config: Partial<BaseOptions> = {
      altInput: true,
      monthSelectorType: 'static',
      dateFormat: 'Y-m-d',
      disableMobile: true,
      nextArrow: '',
      prevArrow: '',
      static: true,
      showMonths: coreCommon.isMobile() ? 1 : 2,
      onMonthChange: (selectedDates: Date[], dateStr: string, instance: Calendar) => {
        this.initEvents(instance)
      },
      onChange: (selectedDates: Date[], dateStr: string, instance: Calendar) => {
        if (selectedDates[0]) {
          // @ts-ignore: altinput is always set
          instance?.altInput.value = new Date(selectedDates[0]).toLocaleDateString(`${currentLocale[0] as string}-${currentLocale[1] as string}`)

          if (this.config.withNights && this.mobileBottomPanelCloseEl) {
            this.mobileBottomPanelCloseEl.click()
          }

          if (this.config.onChange && instance) {
            this.config.onChange(instance.element)
          }
        }
      },
      onReady: (selectedDates: Date[], dateStr: string, instance: Calendar) => {
        this.initEvents(instance)

        if (selectedDates[0]) {
          // @ts-ignore: altinput is always set
          instance?.altInput.value = new Date(selectedDates[0]).toLocaleDateString(`${currentLocale[0] as string}-${currentLocale[1] as string}`)
        }
      },
      onOpen: (selectedDates: Date[], dateStr: string, instance: Calendar) => {
        if (coreCommon.isMobile()) {
          instance?.calendarContainer?.scrollIntoView({
            block: 'center',
            inline: 'nearest',
            behavior: 'smooth',
          })
        }
        if (this.config.onOpen && instance) {
          this.config.onOpen(instance.element)
        }
      },
      onClose: (selectedDates: Date[], dateStr: string, instance: Calendar) => {
        if (this.config.onClose && instance) {
          this.config.onClose(instance.element)
        }
      },
    }

    if (this.config.withNights) {
      config.static = false
      config.inline = coreCommon.isMobile() || coreCommon.isTablet()
      config.appendTo = document.querySelector(
        `#${this.element.id}-mobile-bottompanel .dca-mobile-bottompanel__wrapper .dca-mobile-bottompanel__content`
      ) as HTMLElement
    }

    if (this.config.numberOfMonths) {
      config.showMonths = this.config.numberOfMonths
    }

    if (this.element.hasAttribute('data-select-all-days') === false) {
      // @ts-ignore fp_incr is defined by flatpickr
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      config.minDate = new Date().fp_incr(1)
    }

    const currentYear = new Date().getFullYear()

    if (this.element.hasAttribute('data-mindate')) {
      const [min, max] = this.element.getAttribute('data-mindate')?.split('-') as string[]
      config.minDate = `${currentYear}-${Number(min)}-${Number(max)}`
    }

    if (this.element.hasAttribute('data-maxdate')) {
      const [min, max] = this.element.getAttribute('data-maxdate')?.split('-') as string[]
      config.maxDate = `${currentYear}-${Number(min)}-${Number(max)}`
    }

    if (currentLocale && currentLocale[0]) {
      const instance = CalendarManager.initCalendar(this.element.id, this.element, config, currentLocale[0])

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const gesture = new Gesture(
        instance?.calendarContainer.querySelector<HTMLElement>('.flatpickr-innerContainer') as HTMLElement,
        {
          onDragEnd: (state) => {
            if (!coreCommon.isMobile()) {
              return
            }

            if (state.direction[0] === -1) {
              instance?.changeMonth(1)
            } else if (state.direction[0] === 1) {
              instance?.changeMonth(-1)
            }
          },
        },
        {
          drag: {
            axis: 'x',
          },
        }
      )

      if (instance?.altInput) {
        this.altElement = instance?.altInput
      }

      this.parentEl = this.element.parentNode as Element
    }

    if (this.element.hasAttribute('data-panel-target')) {
      this.altElement.setAttribute('data-panel-target', this.element.getAttribute('data-panel-target') as string)
    }

    if (this.element.hasAttribute('data-date-text')) {
      this.altElement.value = this.element.getAttribute('data-date-text') as string
    }

    this.initWrapper(this.element.id)
  }

  initEvents(instance: Calendar): void {
    instance?.calendarContainer.querySelectorAll<HTMLElement>('.flatpickr-day').forEach((el) => {
      el.addEventListener('mouseover', () => {
        let daysAfter = Array.from(instance.calendarContainer.querySelectorAll<HTMLElement>('.flatpickr-day:not(.hidden)'))

        const indexOfElement = daysAfter.indexOf(el)
        if (indexOfElement !== -1) {
          daysAfter = daysAfter.splice(indexOfElement, daysAfter.length - 1)
        }

        daysAfter.shift()

        this.config.onDayOver?.(instance.calendarContainer, el, daysAfter)
      })

      el.addEventListener('mouseout', () => {
        this.config.onDayOut?.(instance.calendarContainer, el)
      })
    })
  }

  initWrapper(id: string): void {
    const calendar = CalendarManager.getCalendar(id)
    const actionsEl = document.getElementById(`${this.element.id}-actions`)
    const messageEl = document.getElementById(`${this.element.id}-message`)

    if (!actionsEl || !calendar) {
      return
    }

    const label = document.querySelector(`label[for=${id}]`)

    if (label) {
      label.setAttribute('for', `${id}-alt`)
      calendar.altInput?.setAttribute('id', `${id}-alt`)
    }

    actionsEl.removeAttribute('hidden')
    calendar.calendarContainer.appendChild(actionsEl)

    if (messageEl) {
      messageEl.removeAttribute('hidden')
      calendar.calendarContainer.appendChild(messageEl)
    }

    if (this.config.withNights && (coreCommon.isMobile() || coreCommon.isTablet())) {
      mobileBottomPanel(`[data-panel-target="${id}-mobile-bottompanel"]:not([type="hidden"])`)

      this.mobileBottomPanelCloseEl = document.querySelector<HTMLButtonElement>(`#${id}-mobile-bottompanel .dca-mobile-bottompanel__close`)
    }

    this.config.onInitWrapper && this.config.onInitWrapper(calendar, actionsEl)
  }

  destroy(): void {
    const actionsEl = document.getElementById(`${this.element.id}-actions`)
    const messageEl = document.getElementById(`${this.element.id}-message`)

    if (actionsEl) {
      actionsEl.setAttribute('hidden', 'hidden')
      this.parentEl.parentNode?.append(actionsEl)
    }

    if (messageEl) {
      messageEl.setAttribute('hidden', 'hidden')
      this.parentEl.parentNode?.append(messageEl)
    }

    CalendarManager.removeCalendar(this.element.id)
  }
}
